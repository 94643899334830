exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contacto-js": () => import("./../../../src/pages/contacto.js" /* webpackChunkName: "component---src-pages-contacto-js" */),
  "component---src-pages-empresa-js": () => import("./../../../src/pages/empresa.js" /* webpackChunkName: "component---src-pages-empresa-js" */),
  "component---src-pages-ganadores-2025-js": () => import("./../../../src/pages/ganadores2025.js" /* webpackChunkName: "component---src-pages-ganadores-2025-js" */),
  "component---src-pages-ganadores-50-js": () => import("./../../../src/pages/ganadores50.js" /* webpackChunkName: "component---src-pages-ganadores-50-js" */),
  "component---src-pages-ganadores-sevebuenazo-2022-js": () => import("./../../../src/pages/ganadores-sevebuenazo2022.js" /* webpackChunkName: "component---src-pages-ganadores-sevebuenazo-2022-js" */),
  "component---src-pages-ganadores-sevebuenazo-mayo-2024-js": () => import("./../../../src/pages/ganadores-sevebuenazo-mayo-2024.js" /* webpackChunkName: "component---src-pages-ganadores-sevebuenazo-mayo-2024-js" */),
  "component---src-pages-ganadores-sorteo-2021-js": () => import("./../../../src/pages/ganadores-sorteo-2021.js" /* webpackChunkName: "component---src-pages-ganadores-sorteo-2021-js" */),
  "component---src-pages-ganadoresdelsorteo-2023-js": () => import("./../../../src/pages/ganadoresdelsorteo2023.js" /* webpackChunkName: "component---src-pages-ganadoresdelsorteo-2023-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-jp-components-flagmenu-js": () => import("./../../../src/pages/jp/components/flagmenu.js" /* webpackChunkName: "component---src-pages-jp-components-flagmenu-js" */),
  "component---src-pages-jp-components-language-js": () => import("./../../../src/pages/jp/components/language.js" /* webpackChunkName: "component---src-pages-jp-components-language-js" */),
  "component---src-pages-jp-contacto-js": () => import("./../../../src/pages/jp/contacto.js" /* webpackChunkName: "component---src-pages-jp-contacto-js" */),
  "component---src-pages-jp-empresa-js": () => import("./../../../src/pages/jp/empresa.js" /* webpackChunkName: "component---src-pages-jp-empresa-js" */),
  "component---src-pages-jp-ganadores-2025-js": () => import("./../../../src/pages/jp/ganadores2025.js" /* webpackChunkName: "component---src-pages-jp-ganadores-2025-js" */),
  "component---src-pages-jp-ganadores-5-js": () => import("./../../../src/pages/jp/ganadores5.js" /* webpackChunkName: "component---src-pages-jp-ganadores-5-js" */),
  "component---src-pages-jp-ganadores-50-js": () => import("./../../../src/pages/jp/ganadores50.js" /* webpackChunkName: "component---src-pages-jp-ganadores-50-js" */),
  "component---src-pages-jp-ganadores-sevebuenazo-2022-js": () => import("./../../../src/pages/jp/ganadores-sevebuenazo2022.js" /* webpackChunkName: "component---src-pages-jp-ganadores-sevebuenazo-2022-js" */),
  "component---src-pages-jp-ganadores-sevebuenazo-mayo-2024-js": () => import("./../../../src/pages/jp/ganadores-sevebuenazo-mayo-2024.js" /* webpackChunkName: "component---src-pages-jp-ganadores-sevebuenazo-mayo-2024-js" */),
  "component---src-pages-jp-ganadores-sorteo-2021-js": () => import("./../../../src/pages/jp/ganadores-sorteo-2021.js" /* webpackChunkName: "component---src-pages-jp-ganadores-sorteo-2021-js" */),
  "component---src-pages-jp-ganadoresdelsorteo-2023-js": () => import("./../../../src/pages/jp/ganadoresdelsorteo2023.js" /* webpackChunkName: "component---src-pages-jp-ganadoresdelsorteo-2023-js" */),
  "component---src-pages-jp-index-js": () => import("./../../../src/pages/jp/index.js" /* webpackChunkName: "component---src-pages-jp-index-js" */),
  "component---src-pages-jp-navidad-js": () => import("./../../../src/pages/jp/navidad.js" /* webpackChunkName: "component---src-pages-jp-navidad-js" */),
  "component---src-pages-jp-registro-js": () => import("./../../../src/pages/jp/registro.js" /* webpackChunkName: "component---src-pages-jp-registro-js" */),
  "component---src-pages-jp-se-bebe-buenazo-2022-js": () => import("./../../../src/pages/jp/se_bebe_buenazo_2022.js" /* webpackChunkName: "component---src-pages-jp-se-bebe-buenazo-2022-js" */),
  "component---src-pages-jp-sebebebuenazo-reglamento-2024-js": () => import("./../../../src/pages/jp/sebebebuenazo-reglamento2024.js" /* webpackChunkName: "component---src-pages-jp-sebebebuenazo-reglamento-2024-js" */),
  "component---src-pages-jp-sevebuenazo-js": () => import("./../../../src/pages/jp/sevebuenazo.js" /* webpackChunkName: "component---src-pages-jp-sevebuenazo-js" */),
  "component---src-pages-jp-sevebuenazo-mayo-2022-js": () => import("./../../../src/pages/jp/sevebuenazo-mayo-2022.js" /* webpackChunkName: "component---src-pages-jp-sevebuenazo-mayo-2022-js" */),
  "component---src-pages-jp-sevebuenazo-mayo-2024-js": () => import("./../../../src/pages/jp/sevebuenazo-mayo-2024.js" /* webpackChunkName: "component---src-pages-jp-sevebuenazo-mayo-2024-js" */),
  "component---src-pages-jp-sevebuenazo-mayo-2025-js": () => import("./../../../src/pages/jp/sevebuenazo-mayo-2025.js" /* webpackChunkName: "component---src-pages-jp-sevebuenazo-mayo-2025-js" */),
  "component---src-pages-navidad-js": () => import("./../../../src/pages/navidad.js" /* webpackChunkName: "component---src-pages-navidad-js" */),
  "component---src-pages-pt-contacto-js": () => import("./../../../src/pages/pt/contacto.js" /* webpackChunkName: "component---src-pages-pt-contacto-js" */),
  "component---src-pages-pt-empresa-js": () => import("./../../../src/pages/pt/empresa.js" /* webpackChunkName: "component---src-pages-pt-empresa-js" */),
  "component---src-pages-pt-ganadores-2025-js": () => import("./../../../src/pages/pt/ganadores2025.js" /* webpackChunkName: "component---src-pages-pt-ganadores-2025-js" */),
  "component---src-pages-pt-ganadores-5-js": () => import("./../../../src/pages/pt/ganadores5.js" /* webpackChunkName: "component---src-pages-pt-ganadores-5-js" */),
  "component---src-pages-pt-ganadores-50-js": () => import("./../../../src/pages/pt/ganadores50.js" /* webpackChunkName: "component---src-pages-pt-ganadores-50-js" */),
  "component---src-pages-pt-ganadores-sevebuenazo-2022-js": () => import("./../../../src/pages/pt/ganadores-sevebuenazo2022.js" /* webpackChunkName: "component---src-pages-pt-ganadores-sevebuenazo-2022-js" */),
  "component---src-pages-pt-ganadores-sevebuenazo-mayo-2024-js": () => import("./../../../src/pages/pt/ganadores-sevebuenazo-mayo-2024.js" /* webpackChunkName: "component---src-pages-pt-ganadores-sevebuenazo-mayo-2024-js" */),
  "component---src-pages-pt-ganadores-sorteo-2021-js": () => import("./../../../src/pages/pt/ganadores-sorteo-2021.js" /* webpackChunkName: "component---src-pages-pt-ganadores-sorteo-2021-js" */),
  "component---src-pages-pt-ganadoresdelsorteo-2023-js": () => import("./../../../src/pages/pt/ganadoresdelsorteo2023.js" /* webpackChunkName: "component---src-pages-pt-ganadoresdelsorteo-2023-js" */),
  "component---src-pages-pt-index-js": () => import("./../../../src/pages/pt/index.js" /* webpackChunkName: "component---src-pages-pt-index-js" */),
  "component---src-pages-pt-navidad-js": () => import("./../../../src/pages/pt/navidad.js" /* webpackChunkName: "component---src-pages-pt-navidad-js" */),
  "component---src-pages-pt-registro-js": () => import("./../../../src/pages/pt/registro.js" /* webpackChunkName: "component---src-pages-pt-registro-js" */),
  "component---src-pages-pt-se-bebe-buenazo-2022-js": () => import("./../../../src/pages/pt/se_bebe_buenazo_2022.js" /* webpackChunkName: "component---src-pages-pt-se-bebe-buenazo-2022-js" */),
  "component---src-pages-pt-sebebebuenazo-reglamento-2024-js": () => import("./../../../src/pages/pt/sebebebuenazo-reglamento2024.js" /* webpackChunkName: "component---src-pages-pt-sebebebuenazo-reglamento-2024-js" */),
  "component---src-pages-pt-sevebuenazo-js": () => import("./../../../src/pages/pt/sevebuenazo.js" /* webpackChunkName: "component---src-pages-pt-sevebuenazo-js" */),
  "component---src-pages-pt-sevebuenazo-mayo-2022-js": () => import("./../../../src/pages/pt/sevebuenazo-mayo-2022.js" /* webpackChunkName: "component---src-pages-pt-sevebuenazo-mayo-2022-js" */),
  "component---src-pages-pt-sevebuenazo-mayo-2024-js": () => import("./../../../src/pages/pt/sevebuenazo-mayo-2024.js" /* webpackChunkName: "component---src-pages-pt-sevebuenazo-mayo-2024-js" */),
  "component---src-pages-pt-sevebuenazo-mayo-2025-js": () => import("./../../../src/pages/pt/sevebuenazo-mayo-2025.js" /* webpackChunkName: "component---src-pages-pt-sevebuenazo-mayo-2025-js" */),
  "component---src-pages-registro-js": () => import("./../../../src/pages/registro.js" /* webpackChunkName: "component---src-pages-registro-js" */),
  "component---src-pages-se-bebe-buenazo-2022-js": () => import("./../../../src/pages/se_bebe_buenazo_2022.js" /* webpackChunkName: "component---src-pages-se-bebe-buenazo-2022-js" */),
  "component---src-pages-se-bebe-buenazo-2023-js": () => import("./../../../src/pages/se_bebe_buenazo_2023.js" /* webpackChunkName: "component---src-pages-se-bebe-buenazo-2023-js" */),
  "component---src-pages-sebebebuenazo-reglamento-2024-js": () => import("./../../../src/pages/sebebebuenazo-reglamento2024.js" /* webpackChunkName: "component---src-pages-sebebebuenazo-reglamento-2024-js" */),
  "component---src-pages-sevebuenazo-js": () => import("./../../../src/pages/sevebuenazo.js" /* webpackChunkName: "component---src-pages-sevebuenazo-js" */),
  "component---src-pages-sevebuenazo-mayo-2022-js": () => import("./../../../src/pages/sevebuenazo-mayo-2022.js" /* webpackChunkName: "component---src-pages-sevebuenazo-mayo-2022-js" */),
  "component---src-pages-sevebuenazo-mayo-2023-js": () => import("./../../../src/pages/sevebuenazo-mayo-2023.js" /* webpackChunkName: "component---src-pages-sevebuenazo-mayo-2023-js" */),
  "component---src-pages-sevebuenazo-mayo-2024-js": () => import("./../../../src/pages/sevebuenazo-mayo-2024.js" /* webpackChunkName: "component---src-pages-sevebuenazo-mayo-2024-js" */),
  "component---src-pages-sevebuenazo-mayo-2025-js": () => import("./../../../src/pages/sevebuenazo-mayo-2025.js" /* webpackChunkName: "component---src-pages-sevebuenazo-mayo-2025-js" */),
  "component---src-templates-product-js": () => import("./../../../src/templates/product.js" /* webpackChunkName: "component---src-templates-product-js" */),
  "component---src-templates-productj-js": () => import("./../../../src/templates/productj.js" /* webpackChunkName: "component---src-templates-productj-js" */),
  "component---src-templates-productp-js": () => import("./../../../src/templates/productp.js" /* webpackChunkName: "component---src-templates-productp-js" */)
}

